<template>
<div>
  <vs-card>
    <div class="text-center mt-10 mb-10">
      <h2 class="float-center">Bonjour {{ gender("F") }} {{ user.person.firstname }} {{ user.person.lastname }}</h2>
      <p class="aab-home-desc">
        Le compte est sans engagement et vous permet de sauvegarder vos
        informations de souscription pour y revenir quand vous le souhaitez.
      </p>
      <vs-button class="mt-6" @click="$router.push({ name: 'Offer' })">Découvrir nos offres</vs-button>
    </div>
  </vs-card>
</div>

</template>

<script>
import { mapGetters } from "vuex";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    gender(a) {
      let list = { "M": "Monsieur", "F": "Madame" }
      return list[a]
    }
  }
};
</script>

<style>
.aab-home-desc {
  font-size: large;
  margin-left: 10vw;
  margin-right: 10vw;
}
</style>
